import { AppRouter } from "app-shell/app-router/AppRouter.tsx";
import { SessionAuthentication } from "app-shell/session/components/SessionAuthentication.tsx";
import { useMsalConfig } from "app-shell/session/hooks/useMsalConfig.ts";
import { configure } from "mobx";
import promiseFinally from "promise.prototype.finally";
import { FunctionComponent } from "react";

import {
  BpThemeProvider,
  CenteredLargeSpinner,
  customIcons,
  defaultTheme,
  initializeIcons,
  loadTheme,
  registerIcons
} from "@bps/fluent-ui";
import { AuthenticationProvider } from "@bps/http-client";
import { initAppInsights } from "@libs/analytics/app-insights/init.ts";
import { useGoogleAnalyticsInit } from "@libs/analytics/google/index.tsx";
import {
  AppInsightsContext,
  ReactPlugin as AppInsightsReactPlugin
} from "@microsoft/applicationinsights-react-js";
import { StoresProvider } from "@storesProvider";
import { SubscriptionsProvider } from "@subscriptionsProvider";
import { ErrorAlert } from "@ui-components/Alert.tsx";
import { MultiFormPromptsContext } from "@ui-components/form/hooks/MultiFormPromptsContext.ts";
import { MultiFormPromptsController } from "@ui-components/form/hooks/MultiFormPromptsController.ts";
import { useFavicon } from "@ui-components/hooks/useFavicon.ts";

if (import.meta.env.DEV) {
  // observables can only be mutated within actions
  configure({ enforceActions: "always" });
} else {
  configure({ enforceActions: "observed" });
}

// ⚠️ NOTE: loadTheme is temporary until we can invetigate workarounds for Fluent UI/react-charting library overwriting theme.
loadTheme(defaultTheme);

promiseFinally.shim();
const appInsightPlugin = new AppInsightsReactPlugin();

// https://github.com/OfficeDev/@fluentui/react/wiki/Using-icons
initializeIcons(
  "https://static2.sharepointonline.com/files/fabric/assets/icons/"
);

registerIcons({
  fontFace: {
    fontFamily: "BP_custom_icons"
  },
  icons: customIcons
});

const AppSetup: FunctionComponent = () => {
  useGoogleAnalyticsInit();
  initAppInsights({
    extensions: [appInsightPlugin]
  });
  useFavicon();
  return (
    <MultiFormPromptsContext.Provider value={new MultiFormPromptsController()}>
      <SubscriptionsProvider appInsightPlugin={appInsightPlugin}>
        <SessionAuthentication>
          <AppRouter />
        </SessionAuthentication>
      </SubscriptionsProvider>
    </MultiFormPromptsContext.Provider>
  );
};
export const App = () => {
  const config = useMsalConfig();
  return (
    <BpThemeProvider styles={{ height: "100%" }}>
      <AuthenticationProvider
        config={config}
        renderSpinner={message => (
          <CenteredLargeSpinner label={message} labelPosition="bottom" />
        )}
        renderError={message => <ErrorAlert error={message} />}
      >
        <AppInsightsContext.Provider value={appInsightPlugin}>
          <StoresProvider>
            <AppSetup />
          </StoresProvider>
        </AppInsightsContext.Provider>
      </AuthenticationProvider>
    </BpThemeProvider>
  );
};
