import { reaction } from "mobx";
import { FunctionComponent } from "react";

import { AxiosHelperBase, EndpointConfig } from "@bps/http-client";
import { NO_AUTH_HEADER } from "@libs/api/api.constants";
import { HubGateway } from "@libs/api/hub/HubGateway.ts";
import { AuthorizationMiddleware } from "@libs/api/middleware/authorization.middleware";
import { ExceptionFilterMiddleware } from "@libs/api/middleware/exception-filter.middleware";
import { appContext } from "@libs/config/app-context.utils.ts";
import { AccGateway } from "@libs/gateways/acc/AccGateway.ts";
import { BhbGateway } from "@libs/gateways/bhb/BhbGateway.ts";
import { BillingGateway } from "@libs/gateways/billing/BillingGateway.ts";
import { BookingGateway } from "@libs/gateways/booking/BookingGateway.ts";
import { ClinicalGateway } from "@libs/gateways/clinical/ClinicalGateway.ts";
import { CommsGateway } from "@libs/gateways/comms/CommsGateway.ts";
import { CoreGateway } from "@libs/gateways/core/CoreGateway.ts";
import { CustomIntegrationsGateway } from "@libs/gateways/custom-inegration/CustomIntegrationsGateway.ts";
import { DocumentGateway } from "@libs/gateways/document/DocumentGateway.ts";
import { DrugsGateway } from "@libs/gateways/drugs/DrugsGateway.ts";
import { FormDesignGateway } from "@libs/gateways/form-design/FormDesignGateway.ts";
import { FormsGateway } from "@libs/gateways/forms/FormsGateway.ts";
import { InboxDocumentsGateway } from "@libs/gateways/inbox/InboxGateway.interface.ts";
import { NotificationGateway } from "@libs/gateways/notification/NotificationGateway.ts";
import { PracticeGateway } from "@libs/gateways/practice/PracticeGateway.ts";
import { ReportsGateway } from "@libs/gateways/reports/ReportsGateway.ts";
import { TemplateManagementGateway } from "@libs/gateways/template-management/TemplateManagementGateway.ts";
import { GatewaysSchema } from "@libs/gateways/types/gateways-schema.interface.ts";
import { UserExperienceGateway } from "@libs/gateways/user-experience/UserExperienceGateway.ts";
import { AccStore } from "@stores/acc/AccStore.ts";
import { BhbStore } from "@stores/bhb/BhbStore.ts";
import { BillingStore } from "@stores/billing/BillingStore.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { ClinicalStore } from "@stores/clinical/ClinicalStore.ts";
import { CorrespondenceStore } from "@stores/clinical/CorrespondenceStore.ts";
import { CommsStore } from "@stores/comms/CommsStore.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { CustomIntegrationsStore } from "@stores/custom-integration/CustomIntegrationsStore.ts";
import { DocumentStore } from "@stores/documents/DocumentStore.ts";
import { DrugsStore } from "@stores/drugs/DrugsStore.ts";
import { FormDesignStore } from "@stores/form-design/FormDesignStore.ts";
import { FormsStore } from "@stores/forms/FormsStore.ts";
import { StoresContext } from "@stores/hooks/StoresContext.ts";
import { InboxDocumentsStore } from "@stores/inbox/InboxDocumentsStore.ts";
import { NotificationsStore } from "@stores/notifications/NotificationsStore.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";
import { ReportsStore } from "@stores/reports/ReportsStore.ts";
import { RootStore } from "@stores/root/RootStore.ts";
import { RouterStore } from "@stores/router/RouterStore.ts";
import { TemplateManagementStore } from "@stores/template-management/TemplateManagementStore.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

const { config } = appContext;

const gatewaysConfig: EndpointConfig<GatewaysSchema> = {
  acc: { baseURL: config.accBaseUrl },
  billing: { baseURL: config.billingBaseURL },
  inbox: { baseURL: config.inboxDocumentsBaseURL },
  booking: { baseURL: config.bookingBaseURL },
  clinical: { baseURL: config.clinicalBaseURL },
  drugs: { baseURL: config.drugsBaseURL },
  customIntegrations: { baseURL: config.customIntegrationsBaseUrl },
  userExperience: { baseURL: config.userExperienceBaseURL },
  core: { baseURL: config.coreBaseURL },
  practice: { baseURL: config.practiceBaseURL },
  report: { baseURL: config.reportsBaseURL },
  bhb: { baseURL: config.bhbBaseURL },
  forms: { baseURL: config.formsBaseURL },
  comms: { baseURL: config.commsBaseURL },
  document: { baseURL: config.documentBaseURL },
  notification: { baseURL: config.notificationBaseURL },
  formDesign: { baseURL: config.formsBaseURL },
  templateManagement: { baseURL: config.templateManagementURL }
};

const axiosHelper = new AxiosHelperBase<GatewaysSchema>(gatewaysConfig, {
  request: [new AuthorizationMiddleware(NO_AUTH_HEADER)],
  respond: [
    new ExceptionFilterMiddleware(
      appContext.appInsights?.context.telemetryTrace.traceID
    )
  ]
});

const gateways = axiosHelper.initialize();
const hub = new HubGateway(config.signalrBaseURL, {
  autoReconnect: true
});

const accGateway = new AccGateway(gateways.acc);
const billingGateway = new BillingGateway(gateways.billing);
const inboxDocumentsGateway = new InboxDocumentsGateway(gateways.inbox);
const bookingGateway = new BookingGateway(gateways.booking);
const clinicalGateway = new ClinicalGateway(gateways.clinical);
const drugsGateway = new DrugsGateway(gateways.drugs);
const customIntegrationGateway = new CustomIntegrationsGateway(
  gateways.customIntegrations
);

const userExperienceGateway = new UserExperienceGateway(
  gateways.userExperience
);

const coreGateway = new CoreGateway(gateways.core);
const practiceGateway = new PracticeGateway(gateways.practice);

const reportGateway = new ReportsGateway(gateways.report);
const bhbGateway = new BhbGateway(gateways.bhb);
const formsGateway = new FormsGateway(gateways.forms);
const commsGateway = new CommsGateway(gateways.comms);
const documentGateway = new DocumentGateway(gateways.document);
const notificationGateway = new NotificationGateway(gateways.notification);
const formDesignGateway = new FormDesignGateway(gateways.formDesign);
const templateManagementGateway = new TemplateManagementGateway(
  gateways.templateManagement
);

const notification = new NotificationsStore(
  {
    notificationDuration: config.notificationDuration
  },
  notificationGateway,
  hub
);

const router = new RouterStore();
const acc = new AccStore(accGateway, hub);
const core = new CoreStore(coreGateway, hub);

const practice = new PracticeStore(practiceGateway, hub);
const clinical = new ClinicalStore(clinicalGateway, hub);
const billing = new BillingStore(billingGateway, hub);
const inbox = new InboxDocumentsStore(inboxDocumentsGateway, hub);
const booking = new BookingStore(bookingGateway, hub);
const documentStore = new DocumentStore(documentGateway);
const userExperienceStore = new UserExperienceStore(userExperienceGateway, hub);
const drugs = new DrugsStore(drugsGateway);
const correspondence = new CorrespondenceStore(clinicalGateway, hub);
const reports = new ReportsStore(reportGateway, hub);
const bhb = new BhbStore(bhbGateway);
const forms = new FormsStore(formsGateway, hub);
const comms = new CommsStore(commsGateway, hub);
const formDesign = new FormDesignStore(formDesignGateway, hub);
const customIntegrations = new CustomIntegrationsStore(
  customIntegrationGateway,
  hub
);

const templateManagement = new TemplateManagementStore(
  templateManagementGateway,
  hub
);

const rootStore = new RootStore(
  acc,
  router,
  core,
  practice,
  billing,
  booking,
  clinical,
  notification,
  drugs,
  documentStore,
  userExperienceStore,
  correspondence,
  inbox,
  reports,
  comms,
  forms,
  templateManagement,
  bhb,
  formDesign,
  customIntegrations
);

// connect to SignalR hub when user is logged in
// and disconnect from hub when logged off
reaction(
  () => core.isLoggedIn,
  isLoggedIn => {
    if (!isLoggedIn) {
      hub.disconnect();
    } else {
      hub.connect();
    }
  }
);

interface IProps {
  children?: any;
}

const StoresProviderComponent: FunctionComponent<IProps> = props => {
  return (
    <StoresContext.Provider value={rootStore}>
      {props.children}
    </StoresContext.Provider>
  );
};

export const StoresProvider = StoresProviderComponent;
